import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

// redux
import { invertHeader, resetHeader } from "../../actions"
import { connect } from "react-redux"

import CultureList from "./CultureList"

class CultureIndex extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.invertHeader()
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }

  render() {
    return (
      <Layout>
        <SEO 
        seoFields={this.props.data.seoFields}
        title={`Culture`} 
        description={`A deep dive into who we are and how we work.`} />
      <div>
        <CultureList
          data={this.props.data}
          allNews={this.props.data.allNews}
          allQA={this.props.data.allQA}
          allQALength={this.props.data.allQALength.edges.length}
        />
      </div>
      </Layout>
    )
  }
}

export default connect(
  null,
  {
    invertHeader,
    resetHeader,
  }
)(CultureIndex)

export const query = graphql`
  query CultureIndex {
    allNews: allContentfulNews(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" }, displayOnWorking: { eq: true } }
    ) {
      edges {
        node {
          ...newsFragmentBase
        }
      }
    }

    allQA: allContentfulQa(
      limit: 8
      sort: { fields: [createdAt], order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...qaFragment
        }
      }
    }

    allQALength: allContentfulQa(
      sort: { fields: [createdAt], order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
        }
      }
    }

    seoFields: contentfulSeoFields(slug: { eq: "working-seo" }) {
      ...seoFieldsFragment
    }

    workingHeaderImage: contentfulTexts(slug: { eq: "working-header-image" }) {
      ...textFragment
    }

    workingIntroText: contentfulTexts(slug: { eq: "working-intro-text" }) {
      ...textFragment
    }

    workingIntroSubText: contentfulTexts(
      slug: { eq: "working-intro-sub-text" }
    ) {
      ...textFragment
    }
  }
`
