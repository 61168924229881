import React from "react"
import Link from "gatsby-link"
import { withBreakpoints } from "react-breakpoints"
import Fade from "react-reveal/Fade"
import _ from "lodash"
import Plx from "react-plx"

import indexColumns from "../../utils/indexColumns"
import { isOdd } from "../../utils/numbers"
import Text from "../texts/Text"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../preview/ContentPreview"
import QaPreview from "../qa/QaPreview"
import Arrow from "../../svg/Arrow"
import { boxFormats } from "../../components/FigureBox"
import { isMobile } from "../../utils/breakpoints"

class CultureList extends React.Component {
  constructor(props) {
    super(props)

    // all news excluding featured
    this.NewsIndex = this.getNews(0, this.propsNewsLength())

    // column settings
    this.maxColumn = 100 // maximum number of column before reverting count to 1
    this.maxVisible = 100

    this.indexColumns = new indexColumns(0, this.maxColumn)

    this.state = {
      qaVisibleClassName: `is-hidden`,
      qaButtonClassName: ``,
    }
  }

  propsNews() {
    return this.props.allNews && this.props.allNews.edges !== undefined
      ? this.props.allNews.edges
      : this.props.allNews
  }

  propsNewsLength() {
    return this.propsNews() ? this.propsNews().length : 0
  }

  getNews(slice = 0, length) {
    return _.slice(this.propsNews(), slice, length)
  }

  layout(index) {
    let className = []

    // first two on top
    if (index < 2) {
      className.push(`col-12 col-md-6`)
      if (isOdd(this.indexColumns.columnNumber)) {
        className.push(ContentPreviewLayouts.compact_offset_left)
      } else {
        className.push(ContentPreviewLayouts.compact)
      }

      // all others
    } else {
      if (this.indexColumns.columnNumber === 2) {
        className.push(`col-12 col-md-6`)
        className.push(ContentPreviewLayouts.compact_fill)
      } else {
        className.push(`col-12 col-md-6`)
        className.push(ContentPreviewLayouts.compact)
      }
    }

    // left or right gutter on any
    if (isOdd(this.indexColumns.columnNumber)) {
      className.push(`right-gutter`)
    } else {
      className.push(`left-gutter`)
    }

    return className.join(` `)
  }

  listNews(startsAt = 0, max = null) {
    let dataSet = max ? this.getNews(startsAt, max) : this.NewsIndex
    // console.log(this.getNews(startsAt, max))
    if (dataSet.length > 0) {
      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      // will hold content return
      let content = []

      // loop each news
      dataSet.map((news, index) => {
        this.indexColumns.increment()

        content.push(
          <ContentPreview
            key={index}
            index={index}
            contentData={news}
            contentType="culture"
            layout={this.layout(startsAt + index)}
            visible={true}
            delay={this.indexColumns.delay}
          />
        )
      })

      return content
    }
  }

  listQa() {
    let content = []
    let allQA = this.props.data.allQA.edges

    allQA.map((qa, index) => {
      content.push(
        <QaPreview
          key={index}
          index={new Date()}
          qa={qa.node}
          col={`_w-20 _col-lg-auto col-6 col-md-4 col-lg-3 mb-3 --no-padding__right`}
        />
      )
    })
    return content
  }

  headerClassName() {
    if (isMobile(this.props.currentBreakpoint, `tablet`)) {
      return boxFormats.square
    } else if (isMobile(this.props.currentBreakpoint, `tabletLandscape`)) {
      return boxFormats.postalCard
    } else {
      return boxFormats.half
    }
  }

  render() {
    return (
      <section className="working__list">
        <Plx
          parallaxData={[
            {
              start: 0,
              duration: 1250,
              properties: [
                {
                  startValue: 0,
                  endValue: isMobile(this.props.currentBreakpoint) ? -50 : -125,
                  property: `translateY`,
                },
              ],
            },
          ]}
        >
          <Fade speed={450} delay={125}>
            <header className="working__header_image mb-4 mb-xl-5">
              <Text
                text={this.props.data.workingHeaderImage}
                figureFormat={this.headerClassName()}
                overflowContainer={false}
              />
            </header>
          </Fade>
        </Plx>

        <header className="woring__header_intro container-fluid mb-5">
          {/* intro text */}
          <hgroup className="row mb-4">
            <h1 className="col-12 col-md-5">Culture</h1>
            <aside className="col-12 col-md-7 no-padding__right">
              <Text
                text={this.props.data.workingIntroText}
                breakTextAnimation={true}
                textWrapperTag={`h3`}
                container={false}
                contentClassName="col-12 no-padding"
              />
            </aside>
          </hgroup>

          {/* follow us + sub-intro */}
          <hgroup className="row">
            <aside className="col-12 col-md-5 mb-3 mb-md-0">
              <aside className="sharer__label">
                <p className="h5 is-gray-light mb-1">Follow us on</p>
              </aside>

              <ul className="is-unstyled nav col-12 col-md-10 col-xl-7">
                {/* <li className="nav-item pr-2 mb-2">
                  <a
                    href="https://www.facebook.com/wiedenkennedy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h5"
                  >
                    <span>Facebook</span>
                  </a>
                </li> */}

                <li className="nav-item pr-2 mb-2">
                  <a
                    href="https://twitter.com/wiedenkennedy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h5"
                  >
                    <span>Twitter</span>
                  </a>
                </li>

                <li className="nav-item pr-2 mb-2">
                  <a
                    href="https://www.instagram.com/wiedenkennedy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h5"
                  >
                    <span>Instagram</span>
                  </a>
                </li>
              </ul>
            </aside>

            <aside className="col-12 col-md-7">
              <Text
                text={this.props.data.workingIntroSubText}
                contentClassName="col-12 no-padding"
                container={false}
              />
            </aside>

            <div className="col-12">
              <hr />
            </div>
          </hgroup>
        </header>

        {/* first batch of news */}
        <section className="news__list mb-4">
          <div className="row">{this.listNews(0, 2)}</div>
        </section>

        {/* Podcast Iframe */}
        <section className="qa__list container-fluid mb-4">
          <div className="row">
            <div className="col-12 mb-4">
              <hr />
            </div>
            <aside className="col-12 col-md-3">
              <h3 className="h2">Affinity Group Podcast</h3>
            </aside>

            <aside className="col-12 col-md-9 no-padding__right row pb-3">
              <div className="col-12">
                <div className="iframeResponsiveWrapper">
                  <iframe
                    width="100%"
                    height="100%"
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    title="Affinity Group Podcast"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/685290821&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                </div>
              </div>
            </aside>
          </div>
          <div className="col-12">
            <hr />
          </div>
        </section>

        {/* first batch of news */}
        <section className="news__list mb-4">
          <div className="row">{this.listNews(2, 4)}</div>
        </section> 

        {/* Podcast Iframe */}
        {/* <section className="qa__list container-fluid mb-4">
          <div className="row">
            <div className="col-12 mb-4">
              <hr />
            </div>
            <aside className="col-12 col-md-3">
              <h3 className="h2">La Receta Podcast</h3>
            </aside>

            <aside className="col-12 col-md-9 no-padding__right row pb-3">
              <div className="col-12">
                <div className="iframeResponsiveWrapper">
                  <iframe
                    width="100%"
                    height="100%"
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    title="La Receta Podcast"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/801818524&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                </div>
              </div>
            </aside>
          </div>
        </section> */}

        {/* QandA */}
        <section className="qa__list container-fluid mb-4">
          <div className="row">
            <div className="col-12 mb-4">
              <hr />
            </div>
            <aside className="col-12 col-md-3">
              <h3 className="h2">Q&A</h3>
              {/* Show Q&A `See all` button only if we have more than 8 items */}
              {this.props.allQALength > 8 && (
                <p className={this.state.qaButtonClassName}>
                  <Link to={`/culture/q&a/`} className="btn__arrow h5">
                    See all <Arrow direction="right" />
                  </Link>
                </p>
              )}
            </aside>

            <aside className="col-12 col-md-9 no-padding__right row pb-3">
              {this.listQa()}
            </aside>
            <div className="col-12">
              <hr />
            </div>
          </div>
        </section>

        {/* rest of news */}
        <section className="news__list">
          <div className="row">{this.listNews(4, this.propsNewsLength())}</div>
        </section>
      </section>
    )
  }
}

CultureList.propTypes = {}

export default withBreakpoints(CultureList)
